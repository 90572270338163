import { DeviceOrientationControls, OrbitControls } from "@react-three/drei";
import { useGesture } from "@use-gesture/react";
import { useRef } from "react";

export const Controls = ({
  motionPerm,
  cameraGroupRef,
}: {
  motionPerm: boolean;
  cameraGroupRef?: any;
}) => {
  return (
    <>
      {motionPerm && (
        <CombinedControls
          cameraGroupRef={cameraGroupRef}
          motionPerm={motionPerm}
        />
      )}
      <OrbitControls enableZoom={false} enabled={motionPerm ? false : true} />
    </>
  );
};

const CombinedControls = ({ cameraGroupRef }: any) => {
  const cameraGroupRotation = useRef(0);

  useGesture(
    {
      onDragStart: ({ touches }) => {
        if (touches === 1) {
          cameraGroupRotation.current = cameraGroupRef.current.rotation.y;
          console.log("Drag Start");
        }
      },
      onDrag: ({ movement: [s], touches }) => {
        if (touches === 1) {
          cameraGroupRef.current.rotation.y =
            cameraGroupRotation.current + (s / 720) * Math.PI;
          console.log("Dragging", s);
        }
      },
      onDragEnd: ({ touches }) => {
        if (touches === 1) {
          cameraGroupRotation.current = cameraGroupRef.current.rotation.y;
          console.log("Drag End");
        }
      },
    },
    {
      target: window,
      eventOptions: { passive: false },
    }
  );
  return (
    <>
      <DeviceOrientationControls />
    </>
  );
};

export const LoadingScreen = ({ setLottieAnimationLoaded }: any) => {
  return (
    <>
      <div className="loadingScreen">
        <iframe
          onLoad={() => {
            setLottieAnimationLoaded(true);
          }}
          src="https://lottie.host/embed/8e8c7016-9855-4233-a940-9607301e5d58/7LsxZIriAZ.json"
        ></iframe>
        <div className="overlay"></div>
      </div>
    </>
  );
};
